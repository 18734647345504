import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline"},[_c(VDialog,{staticStyle:{"z-index":"999"},attrs:{"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Source "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('geoscript-highlight',{attrs:{"geoscript":_vm.geoscript,"metadata":_vm.metadata || {}}})],1)],1)],1),_vm._t("activator",[_c(VBtn,{on:{"click":function($event){return _vm.showGeoscript()}}},[_vm._v("Source")])],{"show":_vm.showDialog})],2)}
var staticRenderFns = []

export { render, staticRenderFns }