<template>
  <div class="d-inline">
    <v-dialog v-model="dialog" max-width="400" style="z-index: 999">
      <v-card>
        <v-card-title>
          Source
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container>
          <geoscript-highlight :geoscript="geoscript" :metadata="metadata || {}" />
        </v-container>
      </v-card>
    </v-dialog>
    <slot name="activator" :show="showDialog">
      <v-btn @click="showGeoscript()">Source</v-btn>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    geoscript: {
      type: String,
      default: ''
    },
    metadata: {
      type: Object,
      default: null
    }
  },
  components: {
    GeoscriptHighlight: () => import('./GeoscriptHighlight.vue')
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    showDialog() {
      this.dialog = true;
    }
  }
};
</script>

<style>
</style>
